<template>
  <ManageThirdParty dialog-name="Third Parties" />
</template>
<script>
import ManageThirdParty from '@/views/app/manage/ThirdParty'

export default {
  name: 'ThirdPartyWrapper',
  components: {
    ManageThirdParty
  }
}
</script>
